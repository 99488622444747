import Vue from 'vue'
import VueRouter from 'vue-router'
import router from './router'
import App from './App.vue'
import store from './store'
import Vuex from 'vuex'
import CanvasPlugin from 'configurator-base-app'

Vue.use(Vuex);

const mainStore = new Vuex.Store<any>({state: {}});

const settings = {
  VUE_APP_REST_URL: `${process.env.VUE_APP_REST_URL}`,
  VUE_APP_CONFIG_IDENTIFIER: `${process.env.VUE_APP_CONFIG_IDENTIFIER}`,
  VUE_APP_CONFIG_SITE: `${process.env.VUE_APP_CONFIG_SITE}`,
  // VUE_APP_CONFIG_IDENTIFIER: 'MYCLARO',
  // VUE_APP_CONFIG_ESCLUSIVA: `${process.env.VUE_APP_CONFIG_ESCLUSIVA}`,
  // VUE_APP_CONFIG_ESCLUSIVA: 'OSIX',
  // VUE_APP_CONFIG_ESCLUSIVA: 'MYCLARO',
  VUE_APP_COLORS_URL: `${process.env.VUE_APP_COLORS_URL}`,
  VUE_APP_MODEL_URL: `${process.env.VUE_APP_MODEL_URL}`,
  VUE_APP_CONFIG_DATABASE: `${process.env.VUE_APP_CONFIG_DATABASE}`,
  VUE_APP_MULTILINETEMPLES: false,
  VUE_APP_GTAG_ID: `${process.env.VUE_APP_GTAG_ID}`
}

// @ts-ignore
Vue.use(CanvasPlugin, { store: mainStore, settings })


Vue.config.productionTip = false

Vue.use(VueRouter);
new Vue({
  router,
  store: mainStore,
  render: h => h(App),
  created() {
    // @ts-ignore
    Vue.component('Canvas-3d', this.$canvas3d)
  }
}).$mount('#app')
