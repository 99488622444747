
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class GlassesVisualizer extends Vue {
  mounted() {
    console.log("MOUNTED")
    this.$store.commit("configurator/setFinishing", "F");
  }
}
