import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import GlassesVisualizer from '../components/GlassesVisualizer.vue'
import OsixSelection from '../components/OsixSelection.vue'
// import OsixSelection from '../views/OsixSelection.vue';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: "Test",
    component: GlassesVisualizer
  },
   {
    // http://localhost:8080/osix/OV-808SC/PL0001/F/PL0001/F/PRO0000773 url di prova
    path: '/osix/:model/:fcolor/:ffinishing/:tcolor/:tfinishing/:abbellitore/:lenses?',
    name: "OsixSelection",
    component: OsixSelection
   },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
