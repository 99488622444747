
  import { Component, Vue } from 'vue-property-decorator';
  
  @Component
  export default class OsixSelection extends Vue {
    mounted() {
    console.log("MOUNTED", this.$route.params)
    this.$store.commit("configurator/setFinishing", this.$route.params.ffinishing);
    this.$store.state.configurator.tipoAbbellitore = this.$route.params.abbellitore;
  }
  }
  